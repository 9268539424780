@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #39b4ac;
    border-radius: 20px;
  }
  .pagination {
    font-family: var(
      --pagination-font-family,
      system-ui,
      -apple-system,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      'Noto Sans',
      'Liberation Sans',
      Arial,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji'
    );
    font-weight: var(--pagination-font-weight, 400);
    line-height: var(--pagination-line-height, 1.5);
    display: flex;
    padding-left: 0;
    margin-top: var(--pagination-margin-top, 0);
    margin-bottom: var(--pagination-margin-bottom, 0);
    list-style: none;
    justify-content: var(
      --pagination-justify-content,
      var(--pagination-justify-content-fb)
    );
  }
  
  .pagination .page-link {
    position: relative;
    display: block;
    padding: var(--pagination-padding-y, 0.375rem) var(--pagination-padding-x, 0.75rem);
    font-size: var(--pagination-font-size, 1rem);
    color: var(--pagination-color, #0d6efd);
    text-decoration: none;
    background-color: var(--pagination-bg, #fff);
    border: var(--pagination-border-width, 1px) solid
      var(--pagination-border-color, #dee2e6);
    border-radius: var(--pagination-border-radius, 0.375rem);
    margin: 0 var(--pagination-item-margin-x, 0.2rem);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .pagination .page-link {
      transition: none;
    }
  }
  .pagination .page-link:hover {
    z-index: 2;
    color: var(--pagination-hover-color, #0a58ca);
    background-color: var(--pagination-hover-bg, #e9ecef);
    border-color: var(
      --pagination-hover-border-color,
      var(--pagination-border-color, #dee2e6)
    );
  }
  .pagination .page-link:focus {
    z-index: 3;
  }
  .pagination .active > .page-link {
    z-index: 3;
    color: var(--pagination-active-color, var(--pagination-bg, #fff));
    background-color: var(--pagination-active-bg, var(--pagination-color, #0d6efd));
    border-color: var(
      --pagination-active-border-color,
      var(--pagination-active-bg, var(--pagination-color, #0d6efd))
    );
  }
  .pagination .disabled > .page-link {
    color: var(--pagination-disabled-color, #6c757d);
    pointer-events: none;
    background-color: var(--pagination-disabled-bg, var(--pagination-bg, #fff));
    border-color: var(
      --pagination-disabled-border-color,
      var(--pagination-border-color, #dee2e6)
    );
  }
  
  .pagination.justify-content-start {
    --pagination-justify-content-fb: flex-start;
  }
  
  .pagination.justify-content-end {
    --pagination-justify-content-fb: flex-end;
  }
  
  .pagination.justify-content-center {
    --pagination-justify-content-fb: center;
  }